<template>
  <div class="d-flex flex-column flex-row wrapper w-100 p-4">
    <div
      class="container-fluid d-flex justify-content-between align-items-center"
    >
      <Header :children="visibleChildren" />
      <!--begin::Logo-->
      <div class="">
        <div class="symbol-label bg-transparent">
          <div
            v-if="companySelected.logo"
            class="d-flex flex-column justify-content-center"
          >
            <div class="symbol-60 symbol-xl-90">
              <div class="symbol-label">
                <img
                  v-if="companySelected.logo"
                  class="max-h-100px max-w-200px"
                  :src="`${$baseUrl}${companySelected.logo}`"
                />
              </div>
            </div>
            <h4>{{ companySelected.name }}</h4>
          </div>
          <div v-else class="d-flex flex-column justify-content-center">
            <h4>{{ companySelected.name }}</h4>
          </div>
        </div>
      </div>
      <!--end::Logo-->
    </div>
    <div class="content d-flex flex-column flex-column-fluid pt-2">
      <div class="d-flex">
        <div class="container-fluid">
          <router-view />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { getMimetype } from "@/utils/helpers";

import Header from "@/components/controller/Header.component";
import Footer from "@/components/controller/Footer.component";

export default {
  name: "ControllerDashboard",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      stakeholderId: localStorage.getItem("companyId"),
      children: [
        {
          name: "general",
          to: {
            name: "ControllerDashboard"
          }
        },
        {
          name: "multirisk",
          service: "multi_risk",
          to: {
            name: "MultiRiskDashboard"
          }
        },
        {
          name: "awareness",
          service: "awareness",
          to: {
            name: "AwarenessDashboard"
          }
        },
        {
          name: "abs",
          service: "abs",
          to: {
            name: "AbsDashboard"
          }
        },
        {
          name: "reports",
          to: {
            name: "ReportsDashboard"
          }
        }
      ]
    };
  },
  computed: {
    ...mapGetters("Auth", ["hasModule"]),
    ...mapGetters("Controller", [
      "isCompanySelectedLoaded",
      "companySelected",
      "companies"
    ]),
    ...mapGetters("Constants", ["isConstantsActive"]),
    visibleChildren() {
      return _.filter(this.children, item => {
        return item.service === undefined || this.hasModule([item.service]);
      });
    }
  },
  methods: {
    ...mapActions("Auth", ["changeDefaultView"]),
    ...mapActions("Controller", ["loadCompanySelected"]),
    callGetMimetype(base64) {
      return getMimetype(base64);
    }
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.loadCompanySelected();
      }
    },
    isCompanySelectedLoaded(value) {
      if (localStorage.getItem("managedCompanyId") && value === true) {
        this.loadCompanySelected();
      }
    }
  },
  mounted() {
    this.changeDefaultView("ControllerDashboard");

    if (this.companySelected != false && this.isConstantsActive) {
      this.loadCompanySelected();
    }
  }
};
</script>
