<template>
  <div class="header" v-if="companySelected">
    <!--begin::Header Wrapper-->
    <div
      class="header-wrapper rounded-top-xl d-flex flex-grow-1 align-items-center"
    >
      <!--begin::Container-->
      <div class="d-flex align-items-center flex-wrap justify-content-between">
        <!--begin::Menu Wrapper-->
        <div
          class="header-menu-wrapper header-menu-wrapper-left py-lg-2 bg-white rounded-pill shadow"
        >
          <!--begin::Menu-->
          <div
            class="header-menu header-menu-mobile header-menu-layout-default"
          >
            <!--begin::Nav-->
            <ul class="menu-nav">
              <router-link
                v-for="child in children"
                :key="child.name"
                class="menu-item my-1 mx-3 border-white"
                :to="child.to"
                exact-active-class="menu-item-active"
              >
                <li
                  class="menu-link btn-outline-secondary"
                  aria-haspopup="true"
                >
                  <span
                    class="menu-text mx-4 font-weight-bolder"
                    style="white-space: nowrap"
                    >{{ $t(`menu.controller.header.${child.name}`) }}</span
                  >
                </li>
              </router-link>
            </ul>
            <!--end::Nav-->
          </div>
          <!--end::Menu-->
        </div>
        <!--end::Menu Wrapper-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Header Wrapper-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Header",
  computed: {
    ...mapGetters("Controller", ["companySelected"])
  },
  props: {
    children: {
      type: Array,
      reqired: true
    }
  }
};
</script>

<style>
#controller .header-menu .menu-nav > .menu-item.menu-item-here > .menu-link,
#controller .header-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: #0bb783;
  border-color: #0bb783;
  color: white;
}

#controller
  .header-menu
  .menu-nav
  > .menu-item.menu-item-here
  > .menu-link
  .menu-text,
#controller
  .header-menu
  .menu-nav
  > .menu-item.menu-item-active
  > .menu-link
  .menu-text {
  font-weight: bolder;
}

.header-menu .menu-nav > .menu-item > .menu-link {
  border: none;
}
</style>
